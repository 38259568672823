import React from 'react';
import { List, ListItem, Typography, Divider, Grid, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { PlannedProcurement } from './datatypes';

interface PlannedProcurementListProps {
  plannedProcurements: PlannedProcurement[];
  searchTerm: string;  // New prop to pass the search term for highlighting
}

export const PlannedProcurementList: React.FC<PlannedProcurementListProps> = ({
  plannedProcurements,
  searchTerm,  // Destructure searchTerm from props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Function to highlight the search term in the text
  const highlightText = (text: string, highlight: string) => {
    if (!highlight) return text;

    // Case-insensitive search
    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);

    return (
      <>
        {parts.map((part, index) =>
          regex.test(part) ? (
            <span key={index} style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}>
              {part}
            </span>
          ) : (
            <span key={index}>{part}</span>
          )
        )}
      </>
    );
  };

  if (plannedProcurements.length === 0) {
    return <Typography>Ingen planlagte innkjøp funnet.</Typography>;
  }

  return (
    <List>
      {/* Header */}
      <ListItem>
        <ListItemText>
          <Grid container spacing={2}>
            <Grid
              item
              xs={isMobile ? 3 : 2}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  wordBreak: 'break-word',
                  hyphens: 'auto',
                  fontSize: isMobile ? '0.8rem' : '1rem',
                }}
              >
                Tittel
              </Typography>
            </Grid>
            <Grid item xs={isMobile ? 7 : 8}>
              <Typography
                sx={{ fontWeight: 'bold', fontSize: isMobile ? '0.8rem' : '1rem' }}
              >
                Beskrivelse
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                sx={{ fontWeight: 'bold', fontSize: isMobile ? '0.8rem' : '1rem' }}
              >
                Fullførelsesår
              </Typography>
            </Grid>
          </Grid>
        </ListItemText>
      </ListItem>

      {/* Horizontal Divider */}
      <Divider />

      {/* Data Rows */}
      {plannedProcurements.map((plannedProcurement, index) => (
        <div key={index}>
          {/* Data Row */}
          <ListItem>
            <ListItemText>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={isMobile ? 3 : 2}
                >
                  <Typography
                    sx={{
                      wordBreak: 'break-word',
                      hyphens: 'auto',
                      fontSize: isMobile ? '0.8rem' : '1rem',
                    }}
                  >
                    {highlightText(plannedProcurement.title, searchTerm)}
                  </Typography>
                </Grid>
                <Grid item xs={isMobile ? 7 : 8}>
                  <Typography sx={{ fontSize: isMobile ? '0.8rem' : '1rem' }}>
                    {highlightText(plannedProcurement.description, searchTerm)}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{ fontSize: isMobile ? '0.8rem' : '1rem' }}>
                    {plannedProcurement.finishingYear
                      ? dayjs(plannedProcurement.finishingYear).format('YYYY')
                      : '-'}
                  </Typography>
                </Grid>
              </Grid>
            </ListItemText>
          </ListItem>

          {/* Horizontal Divider */}
          <Divider />
        </div>
      ))}
    </List>
  );
};
