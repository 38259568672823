import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Grid,
  Link,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import dayjs from 'dayjs';
import { ProcurementPlan } from './datatypes';
import { PlannedProcurementList } from './PlannedProcurementsList';

interface ProcurementPlansListProps {
  procurementPlans: ProcurementPlan[];
  searchTerm: string;  // Add the search term as a prop here as well
}

const ProcurementPlansList: React.FC<ProcurementPlansListProps> = ({ procurementPlans, searchTerm }) => {
  return (
    <>
      {procurementPlans.map((plan) => (
        <Accordion
          key={plan.id}
          elevation={0}
          square
          sx={{
            border: 'none',
            '&:before': {
              display: 'none',
            },
            marginBottom: 1,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-content-${plan.id}`}
            id={`panel-header-${plan.id}`}
            sx={{
              paddingLeft: 0,
              '& .MuiAccordionSummary-expandIconWrapper': {
                order: -1,
                marginRight: 1,
              },
              '& .MuiAccordionSummary-content': {
                marginLeft: 0,
              },
            }}
          >
            <Typography variant="h6">
              {plan.organizationName}
              {plan.reportYear ? ` (${dayjs(plan.reportYear).format('YYYY')})` : ''}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingLeft: 0 }}>
            {plan.reportUrl && (
              <Typography>
                <Link href={plan.reportUrl} target="_blank" rel="noopener noreferrer">
                  Link til rapport
                  <LaunchIcon fontSize="inherit" style={{ verticalAlign: 'middle' }} />
                </Link>
              </Typography>
            )}
            <Box
              display="flex"
              alignItems="center"
              bgcolor="grey.300"
              padding="10px"
              borderRadius="4px"
              marginTop="20px"
              width="100%"
            >
              <InfoOutlinedIcon sx={{ marginRight: '10px' }} />
              <Typography variant="body1">
                Maskinlagde oppsummeringer kan være feil. Sjekk rapporten for fullstendig informasjon.
              </Typography>
            </Box>
            {plan.description && (
              <Grid item>
                <Box sx={{ fontWeight: 'bold' }} display="inline">
                  Beskrivelse
                </Box>
                <Typography>{plan.description}</Typography>
              </Grid>
            )}
            <Grid item>
              <Box display="inline" sx={{ fontWeight: 'bold' }}>
                Planlagte innkjøp (Maskinlest)
              </Box>
              {/* Pass the searchTerm to PlannedProcurementList for highlighting */}
              <PlannedProcurementList
                plannedProcurements={plan.plannedProcurements}
                searchTerm={searchTerm}  // Pass the search term here
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

// Memoize the component to prevent unnecessary re-renders
export default React.memo(ProcurementPlansList);
