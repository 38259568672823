import React, { useEffect, useState, useMemo } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Link,
  Box,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
  Backdrop,
  MenuItem,
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import ClearIcon from '@mui/icons-material/Clear';
import { useApi } from '../../../utils/hooks/useApi';
import { isAuthenticated, useUserContext } from '../../../utils/contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { ProcurementPlan } from './datatypes';
import ProcurementPlansList from './ProcurementPlansList'; // Import the memoized component

interface ProcurementPlannerProps {}

export const ProcurementPlanner: React.FC<ProcurementPlannerProps> = () => {
  const [procurementPlans, setProcurementPlans] = useState<ProcurementPlan[]>([]);
  const [loading, setLoading] = useState(true);

  // Input fields state
  const [filterTextInput, setFilterTextInput] = useState<string>('');
  const [selectedPlaceNumberInput, setSelectedPlaceNumberInput] = useState<string>('');
  const [plannedProcurementFilterInput, setPlannedProcurementFilterInput] = useState<string>('');

  // Search parameters state
  const [filterText, setFilterText] = useState<string>('');
  const [selectedPlaceNumber, setSelectedPlaceNumber] = useState<string>('');
  const [plannedProcurementFilter, setPlannedProcurementFilter] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(1);
  const rowsPerPage = 20; // Rows per page is fixed at 20
  const { finndoffUser } = useUserContext();
  const navigate = useNavigate();

  const {
    get: getAllProcurementPlans,
    loading: allPlansLoading,
  } = useApi(
    `/api/procurement/all?filterText=${filterText}&selectedPlaceNumber=${selectedPlaceNumber}&currentPage=${currentPage}&rowsPerPage=${rowsPerPage}`
  );

  const { get: getProcurementPlan } = useApi(`/api/procurement`);

  // Fetch plans using the latest filter values
  const fetchPlans = async () => {
    setLoading(true);
    const planList = await getAllProcurementPlans();
    if (planList) {
      // Fetch detailed plans including planned procurements
      const detailedPlansPromises = planList.map(async (plan: ProcurementPlan) => {
        const detailedPlan = await getProcurementPlan(`/${plan.id}`);
        return detailedPlan;
      });
      let detailedPlans = await Promise.all(detailedPlansPromises);

      // Filter plannedProcurements within each plan based on plannedProcurementFilter
      if (plannedProcurementFilter) {
        detailedPlans = detailedPlans.map((plan) => {
          const filteredProcurements = plan.plannedProcurements?.filter(
            (procurement: { title: string; description: string; }) =>
              procurement.title
                .toLowerCase()
                .includes(plannedProcurementFilter.toLowerCase()) ||
              procurement.description
                .toLowerCase()
                .includes(plannedProcurementFilter.toLowerCase())
          );
          return {
            ...plan,
            plannedProcurements: filteredProcurements,
          };
        });
      }

      // Only include plans that have plannedProcurements after filtering
      const plansWithProcurements = detailedPlans.filter(
        (plan) => plan.plannedProcurements && plan.plannedProcurements.length > 0
      );

      setProcurementPlans(plansWithProcurements);
    } else {
      setProcurementPlans([]);
    }
    setLoading(false);
  };

  // UseEffect to trigger fetching plans when search criteria changes
  useEffect(() => {
    fetchPlans().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filterText, selectedPlaceNumber, plannedProcurementFilter]);

  useEffect(() => {
    if (
      isAuthenticated &&
      finndoffUser &&
      finndoffUser?.selectedProduct?.productName !== 'PREMIUM'
    ) {
      navigate('/');
    }
  }, [isAuthenticated, finndoffUser, navigate]);

  const handleClearNameFilter = () => {
    setFilterTextInput('');  // Clear the input field
    setFilterText('');       // Update the search parameter
    setCurrentPage(1);       // Reset page to 1
  };

  const handleClearProcurementFilter = () => {
    setPlannedProcurementFilterInput('');  // Clear the input field
    setPlannedProcurementFilter('');       // Update the search parameter
    setCurrentPage(1);                     // Reset page to 1
  };

  const handleSearch = () => {
    setCurrentPage(1);
    // Update search parameters with input values
    setFilterText(filterTextInput);
    setSelectedPlaceNumber(selectedPlaceNumberInput);
    setPlannedProcurementFilter(plannedProcurementFilterInput);
  };

  // Handle Enter key press for search inputs
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // Memoize the procurement plans list rendering
  const memoizedProcurementPlansList = useMemo(() => {
    if (procurementPlans.length > 0) {
      return (
        <ProcurementPlansList 
          procurementPlans={procurementPlans} 
          searchTerm={plannedProcurementFilter}  // Pass the search term for highlighting
        />
      );
    } else if (!loading) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="30%"
          paddingBottom="30%"
          paddingTop="10%"
        >
          <Typography variant="h6">Ingen resultater funnet</Typography>
        </Box>
      );
    } else {
      return null;
    }
  }, [procurementPlans, loading, plannedProcurementFilter]);

  return (
    <div style={{ position: 'relative' }}>
      {(loading || allPlansLoading) && (
        <Backdrop
          open={loading || allPlansLoading}
          style={{
            zIndex: 1000,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
          }}
        >
          <CircularProgress sx={{ color: 'white' }} />
          <Typography variant="h6" style={{ marginTop: '10px', color: 'white' }}>
            Laster innhold...
          </Typography>
        </Backdrop>
      )}
      <Grid
        container
        spacing={1}
        sx={{ paddingLeft: '16px' }}
        direction={{ xs: 'column', sm: 'row' }}
      >
        {/* Left Panel with Filters */}
        <Grid item xs={12} sm={2}>
          <Grid sx={{ paddingBottom: '15px' }} item>
            <Typography variant="h6">Filtre</Typography>
          </Grid>
          <Grid item>
            <TextField
              label="Filtrer kommuner"
              variant="outlined"
              sx={{ marginBottom: '1rem', paddingRight: '10px' }}
              fullWidth
              value={filterTextInput}
              onChange={(e) => setFilterTextInput(e.target.value)}
              onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {filterTextInput && (
                      <IconButton onClick={handleClearNameFilter} edge="end">
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
                disabled: loading || allPlansLoading,
              }}
            />
            <TextField
              select
              label="Fylker"
              value={selectedPlaceNumberInput}
              onChange={(e) => {
                setSelectedPlaceNumberInput(e.target.value);
                setSelectedPlaceNumber(e.target.value);  // Apply to search parameters
                setCurrentPage(1);                       // Reset to page 1
              }}
              onKeyDown={handleKeyDown} 
              sx={{ marginBottom: '1rem', paddingRight: '10px' }}
              variant="outlined"
              fullWidth
              disabled={loading || allPlansLoading}
            >
              {/* Place Number Options */}
              <MenuItem value="">Alle</MenuItem>
              <MenuItem value="3">Oslo</MenuItem>
              <MenuItem value="11">Rogaland</MenuItem>
              <MenuItem value="15">Møre og Romsdal</MenuItem>
              <MenuItem value="18">Nordland</MenuItem>
              <MenuItem value="31">Østfold</MenuItem>
              <MenuItem value="32">Akershus</MenuItem>
              <MenuItem value="33">Buskerud</MenuItem>
              <MenuItem value="34">Innlandet</MenuItem>
              <MenuItem value="39">Vestfold</MenuItem>
              <MenuItem value="40">Telemark</MenuItem>
              <MenuItem value="42">Agder</MenuItem>
              <MenuItem value="46">Vestland</MenuItem>
              <MenuItem value="50">Trøndelag</MenuItem>
              <MenuItem value="55">Troms</MenuItem>
              <MenuItem value="56">Finnmark</MenuItem>
              <MenuItem value="21">Svalbard</MenuItem>
              <MenuItem value="22">Jan Mayen</MenuItem>
            </TextField>
            <TextField
              label="Søk i planlagte innkjøp"
              variant="outlined"
              sx={{ marginBottom: '1rem', paddingRight: '10px' }}
              fullWidth
              value={plannedProcurementFilterInput}
              onChange={(e) => setPlannedProcurementFilterInput(e.target.value)}
              onKeyDown={handleKeyDown}  
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {plannedProcurementFilterInput && (
                      <IconButton onClick={handleClearProcurementFilter} edge="end">
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
                disabled: loading || allPlansLoading,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              fullWidth
              disabled={loading || allPlansLoading}
              sx={{ marginBottom: '1rem' }}
            >
              Søk
            </Button>
          </Grid>
        </Grid>

        {/* Spacer */}
        <Grid item xs={0.3}></Grid>

        {/* Right Panel with Results */}
        <Grid item xs={12} sm={9}>
          {/* Add 20px buffer at the top */}
          <Box mt={2} />

          {memoizedProcurementPlansList}

          {/* Pagination Controls */}
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button onClick={handlePrevPage} disabled={loading || currentPage === 1}>
              Forrige
            </Button>
            <Button
              onClick={handleNextPage}
              disabled={loading || procurementPlans.length < rowsPerPage}
            >
              Neste
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProcurementPlanner;
