import React, { useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  SelectChangeEvent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { SaveButtonAndModal } from "./SaveButtonAndModal";
import { canSaveSearch, useUserContext } from "../../../../utils/contexts/UserContext";
import { immutablySet } from "../../../../utils/tree";
import { Hierarchy } from "./Hierarchy";
import {
  isNew,
  Search,
  setSearchCheckedKategori,
  setSearchCheckedSted,
  setSearchFreeText,
  setSearchNoticeType,
} from "../../../../Types/Search";
import { useReferenceDataContext } from "../../../../utils/contexts/ReferenceDataContext";
import { useSnackbar } from "../../../../utils/hooks/useSnackbar";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getNodeById } from "../../../../utils/tree";
import { StyledChip } from "../../../../components/StyledChip";

interface SearchFormProps {
  currentSearch: Search;
  setCurrentSearch: (search: Search) => void;
  setActiveTendersOnly: (checked: boolean) => void;
  setFilterNationalTenders: (checked: boolean) => void;
  setFilterIsCenceledTenders: (checked: boolean) => void;
  saveSearch: (search: Search) => Promise<Search>;
  setInitCurrentPage: (id: number) => void;
  sortField: string;       
  sortOrder: "asc" | "desc";  
}

export const SearchForm: React.FC<SearchFormProps> = ({
  currentSearch,
  setCurrentSearch,
  saveSearch,
  setActiveTendersOnly,
  setFilterNationalTenders,
  setFilterIsCenceledTenders,
  setInitCurrentPage,
  sortField,        
  sortOrder,  
}) => {
  const { enqueueSuccessMsg } = useSnackbar();
  const { finndoffUser } = useUserContext();

  const { steder, kategorier } = useReferenceDataContext();
  const [openedKategorier, setOpenedKategorier] = useState<Set<number>>(new Set());
  const [openedSteder, setOpenedSteder] = useState<Set<number>>(new Set());
  const [searchText, setSearchText] = useState<string>("");
  const [checkedActive, setCheckedActive] = useState(false);
  const [checkedNational, setCheckedNational] = useState(currentSearch.filterNationalTenders || false);
  const [checkedIsCanceled, setCheckedIsCanceled] = useState(currentSearch.isCanceled || false);

  // New state variables for valueMin and valueMax
  const [valueMin, setValueMin] = useState<string | null>(null);
  const [valueMax, setValueMax] = useState<string | null>(null);

  const isDefaultSort = sortField === "FileDate" && sortOrder === "desc";
  const isCheckedAndDisabled = !isDefaultSort;
  const [checkedWithParent, setCheckedWithParent] = useState(currentSearch.includeParentCodes);

  const noticeTypeMapping: { [key: string]: string } = {
    "All": "Alle",
    "Pin": "Veiledende",
    "Notice": "Kunngjøring av konkurranse",
    "Award": "Tildeling",
    "Intention": "Intensjon",
  };

  const setInputSearchText = (searchText: string) =>
    setCurrentSearch(
      setSearchFreeText(
        currentSearch,
        searchText,
        currentSearch.kategoriIds,
        currentSearch.stedIds,
        currentSearch.noticeTypes,
        currentSearch.filterNationalTenders,
        currentSearch.isCanceled
      )
    );

  const setInputNoticeType = (types: string[]) => setCurrentSearch(setSearchNoticeType(currentSearch, types));

  const setInputActiveTendersOnly = (checked: boolean) => setActiveTendersOnly(checked);
  const setFilterOutNationalTenders = (checked: boolean) => setFilterNationalTenders(checked);
  const setFilterIsCanceled = (checked: boolean) => setFilterIsCenceledTenders(checked);

  const setCheckedKategori = (id: number, checked: boolean) =>
    setCurrentSearch(setSearchCheckedKategori(currentSearch, kategorier, id, checked));

  const setCheckedSted = (id: number, checked: boolean) =>
    setCurrentSearch(setSearchCheckedSted(currentSearch, steder, id, checked));

  const setOpenedKategori = (id: number, opened: boolean) =>
    setOpenedKategorier(immutablySet(openedKategorier, id, opened));

  const setOpenedSted = (id: number, opened: boolean) => setOpenedSteder(immutablySet(openedSteder, id, opened));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    currentSearch.searchText = event.target.value;
    setSearchText(event.target.value);
    setInitCurrentPage(0);
  };

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    currentSearch.visAktive = event.target.checked;
    setCheckedActive(event.target.checked);
    setInputActiveTendersOnly(currentSearch.visAktive);
    setInitCurrentPage(0);
  };

  const handleFilterNationalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    currentSearch.filterNationalTenders = event.target.checked;
    setCheckedNational(event.target.checked);
    setFilterOutNationalTenders(currentSearch.filterNationalTenders);
    setInitCurrentPage(0);
  };

  
  const handleFilterIsCanceled= (event: React.ChangeEvent<HTMLInputElement>) => {
    currentSearch.isCanceled = event.target.checked;
    setCheckedIsCanceled(event.target.checked);
    setFilterIsCanceled(currentSearch.isCanceled);
    setInitCurrentPage(0);
  };

  // Handlers for valueMin and valueMax changes
  const handleValueMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueMin(event.target.value);
    setCurrentSearch({
      ...currentSearch,
      valueMin: event.target.value ? parseInt(event.target.value) : null,
    });
  };

  const handleValueMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueMax(event.target.value);
    setCurrentSearch({
      ...currentSearch,
      valueMax: event.target.value ? parseInt(event.target.value) : null,
    });
  };

  const doFreetextSearch = () => {
    if (searchText.length > 3) {
      enqueueSuccessMsg("Søker etter..." + currentSearch.searchText);
      setInputSearchText(currentSearch.searchText);
      setInitCurrentPage(0);
    }
  };

  const doNoticeTypeSearch = (event: SelectChangeEvent<string[]>) => {
    let selectedValues = event.target.value as string[]; // Cast to string[] explicitly
    
    const clickedValue = selectedValues[selectedValues.length - 1]; // Get the last clicked value

    if (clickedValue === "All") {
      // If "Alle" is clicked, deselect all other options and keep only "Alle"
      setInputNoticeType(["All"]);
    } else {
      // Remove "Alle" if it's selected and any other option is clicked
      selectedValues = selectedValues.filter((value) => value !== "All");
  
      // Update with new selected options
      setInputNoticeType(selectedValues);
    }
  
    // Reset page after selection change
    setInitCurrentPage(0);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (searchText.length > 3) {
        enqueueSuccessMsg("Søker etter..." + currentSearch.searchText);
        setInputSearchText(currentSearch.searchText);
        setInitCurrentPage(0);
      }
    }
  };

  const tooltip = "Søket støtter også operatorene: * (wildcard)  + (OG) | (ELLER)";

  function stedText() {
    if (currentSearch.stedIds.size < 1) return "Hele Norge";
    else if (currentSearch.stedIds.size === 1)
      return Array.from(currentSearch.stedIds).map((stedId) => getNodeById(steder, stedId)?.label);
    else return "flere steder";
  }

  function kategoriShort() {
    if (currentSearch.kategoriIds.size < 1) return " alle CPV-nummer ";
    else if (currentSearch.kategoriIds.size === 1) return " ett CPV-nummer ";
    else return " flere CPV-nummer ";
  }

  function ProcessExistingSearchName(currentSearchText: string, newSearchName: string) {
    if (
      currentSearchText.includes("Alle anbud") ||
      currentSearchText.includes("CPV-nummer") ||
      currentSearchText.includes("flere steder") ||
      currentSearchText.includes("Hele Norge")
    )
      return newSearchName;
    else return currentSearchText;
  }

  function setValue() {
    var fritekstName = currentSearch.searchText ? currentSearch.searchText : "Alle anbud";
    fritekstName = fritekstName.length > 100 ? "Alle anbud" : fritekstName; // to limit the autognerated search names

    var searchNameCategory = kategoriShort();
    var searchNameLocation = stedText();

    fritekstName = fritekstName + searchNameCategory + searchNameLocation;

    currentSearch.navn = isNew(currentSearch)
      ? fritekstName
      : ProcessExistingSearchName(currentSearch.navn, fritekstName);

    return currentSearch.navn;
  }

  const handleWithParentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    currentSearch.includeParentCodes = event.target.checked;
    setCheckedWithParent(event.target.checked);
    setInputActiveTendersOnly(currentSearch.visAktive);
    setInitCurrentPage(0);
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          color: "#484848",
          margin: "1rem",
        }}
      >
        <Grid container direction="column" spacing={2}>
          {!isNew(currentSearch) && (
            <>
              <Grid item spacing={2}>
                <Typography variant="h6" color="primary">
                  Ditt søk: {currentSearch.navn}
                </Typography>
              </Grid>
            </>
          )}
          {canSaveSearch(finndoffUser) && (
            <Grid item>
              <SaveButtonAndModal
                saveSearch={saveSearch}
                currentSearch={currentSearch}
                initialSearchName={setValue()}
              />
            </Grid>
          )}
          <Grid item>
            <Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "fit-content",
                  }}
                >
                  <Typography variant="h5">Nøkkelord i kunngjøringer</Typography>
                </Box>

                <Box
                  sx={{
                    borderStyle: "solid",
                    border: "0px",
                    borderColor: "gray",
                    borderRadius: "2px",
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      borderStyle: "solid",
                      border: "0px",
                      borderColor: "gray",
                      borderRadius: "2px",
                      display: "flex",
                      flexDirection: "column", // This makes the elements inside stack vertically.
                      width: "100%", // Ensure full width so that the TextField also can expand fully.
                    }}
                  >
                    <Tooltip title={tooltip}>
                    <TextField
                        onChange={handleChange}
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            event.preventDefault(); // Prevent line break when Enter is pressed
                          }
                        }}
                        placeholder="Søk"
                        inputProps={{ "aria-label": "fritekst" }}
                        value={currentSearch.searchText}
                        color="primary"
                        variant="outlined"
                        size="small"
                        multiline
                        maxRows={10} // Expands up to 10 lines
                        fullWidth
                        sx={{
                          backgroundColor: "white",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          overflow: "hidden",
                        }}
                      />
                    </Tooltip>
                  </Box>
                  <Tooltip title={tooltip}>
                    <Button
                      aria-label="search"
                      onClick={doFreetextSearch}
                      endIcon={<SearchIcon />}
                      sx={{
                        height: "inherit",
                        borderBottomRightRadius: "4px",
                        borderTopRightRadius: "4px",
                        float: "right",
                      }}
                      size="large"
                    />
                  </Tooltip>
                </Box>
              </Box>

              <Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isCheckedAndDisabled || checkedActive}
                        onChange={handleActiveChange}
                        color={"primary"}
                        disabled={isCheckedAndDisabled}  // Disabled if non-default sorting
                      />
                    }
                    label="Vis kun aktive"
                  />
                </FormGroup>
              </Box>
              {/* Value Min and Max Fields */}
              <Box marginTop={"1rem"}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Verdi Min"
                      value={valueMin || ""}
                      onChange={handleValueMinChange}
                      type="number"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Verdi Max"
                      value={valueMax || ""}
                      onChange={handleValueMaxChange}
                      type="number"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box marginTop={"1rem"}>
                <FormGroup>
                  <FormControl fullWidth>
                    <Typography variant="h5">Kunngjøringstype</Typography>
                    <Select
                      labelId="kunngjoringstyper"
                      id="kunngjoringstyper-select"
                      multiple
                      value={currentSearch.noticeTypes || []} // Ensure value is an array
                      label="Kunngjøringstyper"
                      onChange={doNoticeTypeSearch}
                      renderValue={(selected) =>
                        selected.map((value) => noticeTypeMapping[value] || value).join(", ") // Map to Norwegian and join with commas
                      }
                    >
                      <MenuItem value={"All"}>
                        <Checkbox checked={currentSearch.noticeTypes.includes("All")} />
                        Alle
                      </MenuItem>
                      <MenuItem value={"Pin"}>
                        <Checkbox checked={currentSearch.noticeTypes.includes("Pin")} />
                        Veiledende
                      </MenuItem>
                      <MenuItem value={"Notice"}>
                        <Checkbox checked={currentSearch.noticeTypes.includes("Notice")} />
                        Kunngjøring av konkurranse
                      </MenuItem>
                      <MenuItem value={"Award"}>
                        <Checkbox checked={currentSearch.noticeTypes.includes("Award")} />
                        Tildeling
                      </MenuItem>
                      <MenuItem value={"Intention"}>
                        <Checkbox checked={currentSearch.noticeTypes.includes("Intention")} />
                        Intensjon
                      </MenuItem>
                    </Select>
                  </FormControl>
                </FormGroup>
                <FormGroup>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedIsCanceled}
                        onChange={handleFilterIsCanceled}
                        color={"primary"}
                      />
                    }
                    label="Vis kun avbrutte"
                  />
                </FormGroup>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant="h5">Sted</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Hierarchy
                    tree={steder}
                    nodes={steder.rootNodes[0].children}
                    openedNodeIds={openedSteder}
                    checkedNodeIds={currentSearch.stedIds}
                    setChecked={setCheckedSted}
                    setOpened={setOpenedSted}
                  />
                </AccordionDetails>
              </Accordion>
              <FormGroup>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedNational}
                        onChange={handleFilterNationalChange}
                        color={"primary"}
                      />
                    }
                    label="Vis kun regionale kunngjøringer"
                  />
                </FormGroup>
              {Array.from(currentSearch.stedIds).map((stedId) => {
                return <StyledChip key={"sted_chip_" + stedId} label={steder.nodeMap[stedId]?.label} />;
              })}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                  <Typography variant="h5">CPV-nummer</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Hierarchy
                    tree={kategorier}
                    nodes={kategorier.rootNodes}
                    openedNodeIds={openedKategorier}
                    checkedNodeIds={currentSearch.kategoriIds}
                    setChecked={setCheckedKategori}
                    setOpened={setOpenedKategori}
                  />
                </AccordionDetails>
              </Accordion>
              {Array.from(currentSearch.kategoriIds).map((kategoriId) => {
                return (
                  <StyledChip
                    key={"kategori_chip_" + kategoriId}
                    label={kategorier.nodeMap[kategoriId].label.split(" ")[0]}
                  />
                );
              })}
            </Box>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={checkedWithParent} onChange={handleWithParentChange} />}
                label="Inkluder CPV-foreldrekoder"
              />
            </FormGroup>
          </Grid>
          {!isNew(currentSearch) && currentSearch.mottakere.length > 0 && (
            <Grid item>
              <Typography variant="subtitle1">Mottakere:</Typography>
              <Grid container spacing={1}>
                {currentSearch.mottakere.map((x, i) => {
                  return (
                    <Grid item key={x}>
                      <StyledChip key={i} label={x} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  );
};
